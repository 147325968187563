import wretch from 'wretch';

export interface CreateHTTPClientOptions {
  baseURL?: string;
  options?: RequestInit;
}

export function createHTTPClient({
  baseURL,
  options,
}: CreateHTTPClientOptions = {}) {
  return wretch(baseURL, options);
}

export type {
  Wretch as HTTPClient,
  WretchResponse as HTTPClientResponse,
  WretchError as HTTPClientError,
  Middleware as HTTPClientMiddleware,
  ConfiguredMiddleware as HTTPClientConfiguredMiddleware,
  WretchResponseChain as HTTPClientResponseChain,
} from 'wretch';

export * from './utils';
export * from './middleware';
export * from './addons';
